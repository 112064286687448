export const routes = {
  home: {
    path: '/',
    name: 'Accueil',
  },
  planning: {
    path: '/planning',
    name: 'Planning',
  },
  servers: {
    path: '/serveurs',
    name: 'Serveurs',
  },
  files: {
    path: '/fichiers',
    name: 'Fichiers',
  }
}