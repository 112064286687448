import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import { IoHome } from 'react-icons/io5'
import { BiServer } from 'react-icons/bi'
import { routes } from './helpers/routes';
import { useTheme } from './hooks/ThemeContext';
import { Notifications } from '@mantine/notifications';
import Navbar from './components/Navbar';
import QuickMenu from './components/QuickMenu';
import Planning from './pages/Planning';
import Servers from './pages/Servers';
import Auth from './pages/Auth';
import LoggedRoute from './hooks/LoggedRoute';
import Authorize from './pages/Authorize';
import NotFound from './pages/NotFound';
import './styles/globals.scss';

function App() {

  const { theme } = useTheme();

  return (
    <div id="app" className={`${theme}`}>
      <Notifications />
      <QuickMenu />
      <Router>
        <Navbar
          links={[
            { title: routes.home.name, icon: <IoHome />, href: routes.home.path },
            { title: routes.servers.name, icon: <BiServer />, href: routes.servers.path },
          ]}

          mobileLinks={[
            { title: routes.home.name, icon: <IoHome />, href: routes.home.path },
            { title: routes.servers.name, icon: <BiServer />, href: routes.servers.path },
          ]}
        />
        <Switch>
          <Route path="/" element={<LoggedRoute><Planning /></LoggedRoute>} />
          <Route path="/serveurs" element={<LoggedRoute><Servers /></LoggedRoute>} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/authorize" element={<Authorize />} />
          <Route path="*" element={<NotFound />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
