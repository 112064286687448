import { useEffect } from 'react'
import { useTheme } from '../hooks/ThemeContext';
import ParallaxContainer from '../components/ParallaxContainer';
import '../styles/pages/notFound.scss'

export default function Auth() {

  useEffect(() => {
    document.title = 'IGB Planner | Page non trouvée'
    const quickMenu = document.getElementById('quickMenu');
    const navbar = document.getElementById('navbar');

    if (quickMenu) quickMenu.style.display = 'none';
    if (navbar) navbar.style.display = 'none';
  }, [])

  return (
    <div id="notFound">
      <div>
        <p className="title">404</p>
        <p className="subtitle">Page non trouvée</p>
        <a href="/">Retour à l'accueil</a>
      </div>
      <ParallaxContainer />
    </div>
  )
}
