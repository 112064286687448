import { useEffect, useState } from 'react';
import ParallaxContainer from '../components/ParallaxContainer';
import axios from 'axios';
import { useSearchParams } from "react-router-dom";
import '../styles/pages/auth.scss'
import { Loader } from '@mantine/core';


export default function Authorize() {

  const [searchParams] = useSearchParams();
  const [discordToken, setDiscordToken] = useState<any>({});
  const [discordUser, setDiscordUser] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [currentState, setCurrentState] = useState(0);

  useEffect(() => {
    document.title = 'IGB Planner | Authentification'
    const quickMenu = document.getElementById('quickMenu');
    const navbar = document.getElementById('navbar');

    if (quickMenu) quickMenu.style.display = 'none';
    if (navbar) navbar.style.display = 'none';
  }, [])

  useEffect(() => {
    const url = `https://discord.com/api/oauth2/token`
    const params = `grant_type=authorization_code&code=${searchParams.get('code')}&redirect_uri=${process.env.REACT_APP_DISCORD_FINAL_REDIRECT}&client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&client_secret=${process.env.REACT_APP_DISCORD_CLIENT_SECRET}`
    // console.log(url);

    axios.post(url, params, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } })
      .then((response) => {
        setDiscordToken(response.data)
        localStorage.setItem('igb_token', JSON.stringify(response.data))
        console.log(response.data);

      })
  }, [searchParams])

  useEffect(() => {
    if (discordToken.access_token) {
      const url = `https://discord.com/api/users/@me`
      const headers = { 'Authorization': `Bearer ${discordToken.access_token}` }
      axios.get(url, { headers })
        .then((response) => {
          setDiscordUser(response.data)
          setCurrentState(1)
          setLoading(false)
        })
    }
  }, [discordToken])

  useEffect(() => {
    if (discordUser.id) {
      axios.get(process.env.REACT_APP_API_URL + '/users')
        .then((response) => {
          console.log(response.data);

          const user = response.data.find((user: any) => user.id === discordUser.id)
          console.log(discordUser);

          const customUser = {
            ...user,
            ...discordUser
          }
          if (user) {
            localStorage.setItem('igb_user', JSON.stringify(customUser))
            setCurrentState(2)
            setLoading(false)
            window.location.href = '/'
          } else {
            setCurrentState(3)
            setLoading(false)
          }
        })
    }
  }, [discordUser])

  return (
    <div id="authorize">
      <div className="header">
        <h1>Vous êtes connectés 💪</h1>
      </div>
      <div className="form-container">
        {
          loading ? <Loader /> : null
        }
        {currentState === 0 && <p id='input-label'>Récupération du profil...</p>}
        {currentState === 1 && <p id='input-label'>Autorisation en cours...</p>}
        {currentState === 2 && <p id='input-label'>Autorisé, redirection...</p>}
        {currentState === 3 && <p id='input-label'>Vous n'êtes pas autorisé à accéder à cette page, veuillez contacter un administrateur.</p>}
      </div>
      <ParallaxContainer />
    </div>
  )
}