import React, { useEffect, useState } from 'react'
import { DatePicker } from '@mantine/dates';
import { Indicator } from '@mantine/core';
import { useTheme } from '../hooks/ThemeContext';
import { colors } from '../helpers/variables';
import { Event } from '../helpers/types';
import DetailEvent from '../components/DetailEvent';
import axios from 'axios';
import dayjs from 'dayjs';
import '../styles/pages/planning.scss'
import { BiCalendarEdit, BiCalendarEvent, BiHistory, BiListCheck, BiListOl, BiListUl, BiSkipNext, BiSkipNextCircle } from 'react-icons/bi';
import { IconContext } from 'react-icons';

export default function Planning() {

  const { theme } = useTheme();

  const [events, setEvents] = useState<Event[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());

  useEffect(() => {
    getEvents()
  }, [])

  const getEvents = async () => {
    axios.get(`${process.env.REACT_APP_API_URL}/schedules/628274535677952031`)
      .then((response) => {
        console.log(response.data);
        setEvents(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  return (
    <div id="planning">
      {/* <h1>Planning</h1> */}
      <div className="planning-viewer">
        <div>
          <div className={`calendar-container ${theme}`}>
            <DatePicker
              size='md'
              maxLevel='month'
              allowDeselect
              renderDay={(date) => {
                const event = events.find((event) => new Date(event.startDate).toDateString() === date.toDateString());
                return event ? <Indicator color={colors.orange}>
                  <p className={`${(dayjs(date).day() === 0 || dayjs(date).day() === 6) && 'red'}`}>{dayjs(date).format('DD')}</p>
                </Indicator> : null;
              }}
              onChange={(date) => setSelectedDate(date)}
              value={selectedDate}
            />
          </div>
          <IconContext.Provider value={{ style: { fontSize: "1.2rem", marginRight: '5px' } }}>
            <div className={`dashboard-container ${theme}`}>
              <p className="title">Dashboard</p>
              <div className="stats-container">
                <div className="stat-box">
                  <div className="stat-title-container">
                    <BiListUl />
                    <p className="stat-title">Évennements</p>
                  </div>
                  <p className="stat-value">{events.length}</p>
                </div>
                <div className="stat-box">
                  <div className="stat-title-container">
                    <BiCalendarEvent />
                    <p className="stat-title">Évennements ce mois-ci</p>
                  </div>
                  <p className="stat-value">{events.filter((event) => dayjs(event.startDate).month() === dayjs().month()).length}</p>
                </div>
                <div className="stat-box">
                  <div className="stat-title-container">
                    <BiHistory />
                    <p className="stat-title">Évennements passés</p>
                  </div>
                  <p className="stat-value">{events.filter((event) => dayjs(event.startDate).isBefore(dayjs())).length}</p>
                </div>
                <div className="stat-box">
                  <div className="stat-title-container">
                    <BiSkipNextCircle />
                    <p className="stat-title">Évennements à venir</p>
                  </div>
                  <p className="stat-value">{events.filter((event) => dayjs(event.startDate).isAfter(dayjs())).length}</p>
                </div>
              </div>
            </div>
          </IconContext.Provider>
        </div>
        <div className="planning-detail-container">
          {
            selectedDate ?
              events.filter((event) => new Date(event.startDate).toDateString() === selectedDate.toDateString()).length > 0 ?
                events.filter((event) => new Date(event.startDate).toDateString() === selectedDate.toDateString()).map((event, event_index) => {
                  return (
                    <div className="planning-detail">
                      <DetailEvent
                        event={event}
                      />
                    </div>
                  )
                })
                :
                <div className="planning-detail no-detail">
                  <h3>Aucun évennement pour le {dayjs(selectedDate).format('DD/MM/YYYY')}</h3>
                </div>
              :
              <div className="planning-detail no-detail">
                <h3>Selectionnez un jour pour voir le détail des évennements</h3>
              </div>
          }
        </div>
      </div>
    </div>
  )
}
