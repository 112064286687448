import { ReactNode, useEffect, useState } from 'react'
import { IconContext } from 'react-icons/lib'
import { colors } from '../helpers/variables'
import { useTheme } from '../hooks/ThemeContext'
import { Link } from 'react-router-dom'
import '../styles/components/navbar.scss'

interface NavbarProps {
  links: { title: string, icon: ReactNode, href: string }[]
  mobileLinks: { title: string, icon: ReactNode, href: string }[]
}

export default function Navbar({ links, mobileLinks }: NavbarProps) {

  const { theme } = useTheme();

  const [isMobile, setIsMobile] = useState(false)
  const [username, setUsername] = useState('')
  const [userPicture, setUserPicture] = useState('')

  useEffect(() => {
    setIsMobile(window.innerWidth <= 992)
  }, [])

  useEffect(() => {
    if (localStorage.getItem('igb_user')) {
      const user = JSON.parse(localStorage.getItem('igb_user')!)

      setUserPicture(user.picturUrl)
      setUsername(user.username)
    }
  }, [])

  //Add an event listener to the window to swith the navbar to the mobile version when the window is resized
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  function handleResize() {
    setIsMobile(window.innerWidth <= 992)
  }

  return (
    <>
      {
        !isMobile ?
          <nav id="navbar" className="navbar">
            <div className="navbar-user-container">
              <img src={userPicture} alt="User" />
              <p>{username}</p>
            </div>
            <div className="items-container">
              {
                links.map((link, link_index) => {
                  return (
                    <IconContext.Provider value={{ color: colors.white, style: { verticalAlign: 'middle' } }} key={link_index}>
                      <div className="navbar-item">
                        <Link className={`navbar-item-link ${theme}`} to={link.href}>
                          <span>{link.icon}</span>
                          <span>{link.title}</span>
                        </Link>
                      </div>
                    </IconContext.Provider>
                  )
                })
              }
            </div>
          </nav>
          :
          <nav className="navbar-mobile">

            {
              mobileLinks.map((link, link_index) => {
                return (
                  <IconContext.Provider value={{ color: colors.white, style: { verticalAlign: 'middle' } }} key={link_index}>
                    <div className="navbar-mobile-item">
                      <Link className="navbar-mobile-item-link" to={link.href}>
                        <span>{link.icon}</span>
                        <span>{link.title}</span>
                      </Link>
                    </div>
                  </IconContext.Provider>
                )
              })
            }
          </nav>
      }
    </>
  )
}
