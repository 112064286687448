import dayjs from 'dayjs';
import { useTheme } from '../hooks/ThemeContext';
import { BsCalendar3, BsLink45Deg, BsMegaphone, BsPeopleFill, BsPerson, BsPinMap, BsTextLeft } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import { Tooltip } from '@mantine/core';
import { colors } from '../helpers/variables';
import { notifications } from '@mantine/notifications';
import '../styles/components/detailEvent.scss'

interface DetailEventProps {
  event: any;
  shadow?: boolean;
}


export default function DetailEvent({ event, shadow }: DetailEventProps) {

  const { theme } = useTheme();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
    notifications.show({
      title: 'Lien copié !',
      message: 'Le lien a été copié dans votre presse-papier.',
      color: 'green',
      styles: () => ({
        root: {
          backgroundColor: theme === 'dark' ? colors.dark_grey : colors.white,
        },

        title: { color: theme === 'dark' ? colors.white : colors.dark_grey },
        description: { color: theme === 'dark' ? colors.white : colors.dark_grey },
        closeButton: {
          color: theme === 'dark' ? colors.white : colors.dark_grey,
        },
      }),
    })
  }

  return (
    <IconContext.Provider value={{ color: theme === 'light' ? '#000' : '#fff', size: '1.2rem', style: { verticalAlign: 'middle', marginRight: '10px' } }}>
      <div className={`detailEvent ${theme} ${shadow ? 'shadow' : ""}`}>
        <div className="event-header">
          <p className="event-title">{event.name}</p>
          <div className="buttons-container">
            <IconContext.Provider value={{ size: '1.2rem', style: { verticalAlign: 'middle', marginRight: '10px', fill: colors.white } }}>
              <Tooltip label="Bientôt disponible !" color='gray'>
                <button className="event-add-to-calendar-button disabled"><BsCalendar3 /> Ajouter au calendrier</button>
              </Tooltip>
              <button onClick={() => copyToClipboard(event.link)} className="event-add-to-calendar-button"><BsLink45Deg /> Copier le lien</button>
            </IconContext.Provider>
          </div>
        </div>
        <div className="event-sub-header">
          <p>Le {dayjs(event.startDate).format('DD/MM/YYYY')} à {dayjs(event.startDate).format('HH:mm').replace(':', 'h')}</p>
        </div>
        <div className="event-body">
          <div className="event-body-column">
            <div className="event-body-item">
              <BsTextLeft />
              <p>{event.description}</p>
            </div>
            <div className="event-body-item">
              <BsPinMap />
              <p>{event.customLoc ? event.customLoc : "Lieu non précisé"}</p>
            </div>
          </div>
          <div className="event-body-column">
            <div className="event-body-item">
              {event.channelId ? <BsMegaphone /> : <BsMegaphone />}
              <p>{event.channelId ? "Dans un salon vocal" : "Pas de salon vocal précisé"}</p>
            </div>
            <div className="event-body-item">
              <BsPerson />
              <p>{event.creator.username}</p>
            </div>
          </div>
          <div className="event-body-column">
            <div className="event-body-item">
              {<BsPeopleFill />}
              <div className="participants-pp-container">
                {
                  event.participants.map((participant: any, index: number) => {
                    return (
                      <Tooltip key={index} label={participant.user.username} variant='outline' >
                        <img src={participant.user.picturUrl} alt={participant.user.username} />
                      </Tooltip>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </IconContext.Provider>
  )
}
