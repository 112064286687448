import { ReactNode, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom';

interface LoggedRouteProps {
  children: ReactNode;
}

export default function LoggedRoute({ children }: LoggedRouteProps) {

  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('igb_user') && localStorage.getItem('igb_user') !== '') {
      console.log('user found');
      setIsLogged(true);
      setIsLoading(false);
    } else {
      console.log('no user found');
      setIsLogged(false);
      setIsLoading(false);
    }
  }, [])

  return (
    <>
      {isLoading ? <div>Loading...</div> : isLogged ? children : <Navigate to="/auth" />}
    </>
  )
}
