import { useEffect } from 'react'
import { useTheme } from '../hooks/ThemeContext';
import { BsMoonFill, BsSunFill } from 'react-icons/bs';
import ParallaxContainer from '../components/ParallaxContainer';
import '../styles/pages/auth.scss'


export default function Auth() {

  const { theme, switchTheme } = useTheme()

  useEffect(() => {
    document.title = 'IGB Planner | Authentification'
    const quickMenu = document.getElementById('quickMenu');
    const navbar = document.getElementById('navbar');

    if (quickMenu) quickMenu.style.display = 'none';
    if (navbar) navbar.style.display = 'none';
  }, [])

  const handleDisordLogin = () => {
    window.location.href = `https://discord.com/oauth2/authorize?client_id=${process.env.REACT_APP_DISCORD_CLIENT_ID}&scope=identify+guilds&response_type=code&callback_uri=${process.env.REACT_APP_DISCORD_REDIRECT_URL}`
  }

  return (
    <div id="auth">
      <div className="header">
        <h1>Bienvenue sur <br />IGB Planner 👋</h1>
      </div>
      <div className="form-container">
        <button className={`discord-button`} onClick={() => handleDisordLogin()}>Connexion avec discord</button>
        <div className="form-submit-row">
          <button type='button' className={`quick-menu-item ${theme}`} onClick={() => switchTheme()}>
            {theme === 'light' ? <BsMoonFill size={'1.2rem'} /> : <BsSunFill size={'1.2rem'} />}
          </button>
        </div>
      </div>
      <ParallaxContainer />
    </div>
  )
}