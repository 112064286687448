import { BsFillShieldLockFill, BsMoonFill, BsPower, BsSunFill } from 'react-icons/bs'
import { useTheme } from '../hooks/ThemeContext';
import { IconContext } from 'react-icons/lib';
import { colors } from '../helpers/variables';
import '../styles/components/quickMenu.scss'
import { FaPowerOff } from 'react-icons/fa';

export default function QuickMenu() {

  const { theme, switchTheme } = useTheme();

  const signout = () => {
    localStorage.removeItem('igb_user');
    localStorage.removeItem('igb_token');
    window.location.href = '/auth';
  }

  return (
    <div id="quickMenu" className={`quick-menu ${theme}`}>
      <IconContext.Provider value={{ style: { color: theme === 'light' ? colors.dark_grey : colors.white, fontSize: '1.2rem', verticalAlign: 'middle' } }}>
        <button type='button' className={`quick-menu-item ${theme}`} onClick={() => switchTheme()}>
          {theme === 'light' ? <BsMoonFill /> : <BsSunFill />}
        </button>
        <button type='button' className={`quick-menu-item ${theme}`}>
          <BsFillShieldLockFill />
        </button>
        <button type='button' className={`quick-menu-item ${theme}`} onClick={() => signout()}>
          <FaPowerOff />
        </button>
      </IconContext.Provider>
    </div>
  )
}
