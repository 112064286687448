export const colors = {
  dark_grey: "#121826",
  light_grey: "#1d2532",
  white: "#ffffff",
  orange: "#FA862E",
  purple: "#875CFF",
  green: "#00D1B2",
  red: "#FF3860",
  yellow: "#f2e6bc",
  blue: "#00B0FF",
  light_blue: "#b9d4ff",
  pink_red: "#ffb1b1",
}