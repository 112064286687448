import React, { useRef } from 'react'
import '../styles/components/parallaxContainer.scss'

export default function ParallaxContainer() {

  const layer1 = useRef<any>(null);
  const layer2 = useRef<any>(null);
  const layer3 = useRef<any>(null);
  const layer4 = useRef<any>(null);

  // Magic happens here
  const parallax = (e: MouseEvent) => {
    let _w = window.innerWidth / 2;
    let _h = window.innerHeight / 2;
    let _mouseX = e.clientX;
    let _mouseY = e.clientY;

    let layer1_transformation = `${50 - (_mouseX - _w) * 0.01}% ${50 - (_mouseY - _h) * 0.01}%`;
    let layer2_transformation = `${50 - (_mouseX - _w) * 0.004}% ${50 - (_mouseY - _h) * 0.004}%`;
    let layer3_transformation = `${50 - (_mouseX - _w) * 0.006}% ${50 - (_mouseY - _h) * 0.006}%`;
    let layer4_transformation = `${50 - (_mouseX - _w) * 0.002}% ${50 - (_mouseY - _h) * 0.002}%`;

    if (layer1.current && layer2.current && layer3.current && layer4.current) {
      layer1.current.style.transform = `translate(${layer1_transformation.split(' ')[0]}, ${layer1_transformation.split(' ')[1]}) scaleX(-1)`;
      layer2.current.style.transform = `translate(${layer2_transformation.split(' ')[0]}, ${layer2_transformation.split(' ')[1]}) rotate(20deg)`;
      layer3.current.style.transform = `translate(${layer3_transformation.split(' ')[0]}, ${layer3_transformation.split(' ')[1]}) rotate(-10deg)`;
      layer4.current.style.transform = `translate(${layer4_transformation.split(' ')[0]}, ${layer4_transformation.split(' ')[1]})`;
    }
  }

  document.addEventListener("mousemove", parallax);

  return (
    <div id="parallaxContainer">
      <img ref={layer1} id="parallax-creeper" className="parallax-layer parallax-creeper" src="/img/illustrations/creeper.png" alt="Creeper Head" />
      <img ref={layer2} id="parallax-mic" className="parallax-layer parallax-mic" src="/img/illustrations/microphone.png" alt="Microphone" />
      <img ref={layer3} id="parallax-coffee" className="parallax-layer parallax-coffee" src="/img/illustrations/coffee-cup.png" alt="Coffee cup" />
      <img ref={layer4} id="parallax-gamepad" className="parallax-layer parallax-gamepad" src="/img/illustrations/gamepad.png" alt="Game controller" />
    </div>
  )
}
