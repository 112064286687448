import { useTheme } from '../hooks/ThemeContext';
import '../styles/pages/servers.scss'


export default function Servers() {

  const { theme } = useTheme();

  return (
    <div id="servers">
      <h1>Serveurs</h1>
      <div className="servers-viewer">

      </div>
    </div>
  )
}
