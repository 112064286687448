import { ReactNode, createContext, useContext, useEffect, useState } from "react";

type Theme = "light" | "dark";

type ThemeContextType = {
  theme: Theme;
  switchTheme: () => void;
};

const defaultTheme: Theme = "light";

export const ThemeContext = createContext<ThemeContextType>({
  theme: defaultTheme,
  switchTheme: () => { },
});

interface ThemeProviderProps {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useState<Theme>(defaultTheme);

  useEffect(() => {
    const localTheme = localStorage.getItem("theme");
    if (localTheme) {
      setTheme(localTheme as Theme);
    }
  }, []);

  const switchTheme = () => {
    console.log("switchTheme");

    setTheme((currentTheme) =>
      currentTheme === "light" ? "dark" : "light"
    );
    localStorage.setItem("theme", theme === "light" ? "dark" : "light");
  };

  const contextValue = {
    theme,
    switchTheme,
  };

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextType => {
  const contextValue = useContext(ThemeContext);
  return contextValue;
};